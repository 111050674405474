import { Button, Typography, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import FileSaver from "file-saver";
import useWebSocket from "react-use-websocket"
import { cityIOSettings } from "../../../../settings/settings";

export default function PhotoMenu(props) {
  const [imageName, setImageName] = useState('default');

  const { sendJsonMessage } = useWebSocket(
    cityIOSettings.cityIO.websocketURL,
    {
      share: true,
      shouldReconnect: () => true,
    },
  )


  const   handleDownload = () => {
    console.log("download");
    console.log(props.refMap, props.refDeckgl);
    if (!props.refMap || !props.refDeckgl.current) {
      return;
    }
    const mapGL = props.refMap.getMap();
    const deck = props.refDeckgl.current.deck;
    deck.redraw(true);
    mapGL.triggerRepaint(true);
    console.log(mapGL)
    const mapboxCanvas = mapGL.getCanvas();
    const deckglCanvas = deck.getCanvas();
    console.log(mapboxCanvas, deckglCanvas);
    let merge = document.createElement("canvas");
    merge.width = mapboxCanvas.width;
    merge.height = mapboxCanvas.height;

    var context = merge.getContext("2d");

    context.globalAlpha = 1.0;
    context.drawImage(mapboxCanvas, 0, 0);
    context.globalAlpha = 1.0;
    context.drawImage(deckglCanvas, 0, 0);

    merge.toBlob((blob) => {
      console.log(blob.text())
      FileSaver.saveAs(blob, imageName);
    });
  };

  const   handleUpload = () => {
    console.log("download");
    console.log(props.refMap, props.refDeckgl);
    if (!props.refMap || !props.refDeckgl.current) {
      return;
    }
    const mapGL = props.refMap.getMap();
    const deck = props.refDeckgl.current.deck;
    deck.redraw(true);
    mapGL.triggerRepaint(true);
    console.log(mapGL)
    const mapboxCanvas = mapGL.getCanvas();
    const deckglCanvas = deck.getCanvas();
    console.log(mapboxCanvas, deckglCanvas);
    let merge = document.createElement("canvas");
    merge.width = mapboxCanvas.width;
    merge.height = mapboxCanvas.height;

    var context = merge.getContext("2d");

    context.globalAlpha = 1.0;
    context.drawImage(mapboxCanvas, 0, 0);
    context.globalAlpha = 1.0;
    context.drawImage(deckglCanvas, 0, 0);

    merge.toBlob((blob) => {
      console.log(blob)
      blob.arrayBuffer().then(function(arrayBuffer) {
        var image = _arrayBufferToBase64(arrayBuffer);
        console.log("sent_image",image)
        sendJsonMessage({
          type: "SAVE_IMAGE",
          content: {
            "name": imageName,
            "image": image
          },
        })
      });
    });
    
  };

  function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}


  return (
    <>
      <TextField
      fullWidth
        hiddenLabel
        id="filled-hidden-label-small"
        placeholder="Enter image name"
        variant="filled"
        size="small"
        onChange={(event) => {
          setImageName(event.target.value);
        }}
      
      />
      <Button
          fullWidth
          id={"EDIT_BUTTON"}
          variant="outlined"
          endIcon={<EditIcon />}
          onClick={() => handleDownload()}
        >
          <Typography>
            Generate image
          </Typography>
        </Button>

        <Button
        fullWidth
        id={"EDIT_BUTTON"}
        variant="outlined"
        endIcon={<CloudUploadIcon />}
        onClick={() => handleUpload()}
      >
        <Typography>
          Upload image
        </Typography>
      </Button>
    </>
    
  );
}
